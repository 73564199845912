import React, { useEffect, useState } from "react";
import "./Projects.scss";
import Loader from "../../components/loader/loader";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MAP_ICON from "./../../assets/images/map-icon.png";
import LightBoxGallery from "../lightbox-gallery/LightBoxGallery";
import { canView, truncateText } from "../../utils/util";
import ListHeader from "../../components/list-header/ListHeader";
import { createRecord, getRecords } from "../../redux/reducers";
import { Button, Form as bootstrapForm, Modal, Table } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Form from "../../components/form/Form";
import { deleteRecord } from "../../redux/reducers/recordSlice";
import { baseUrl } from "../../redux";
import { FaArrowCircleLeft } from "react-icons/fa";
import { USER_ROLE } from "../../Constants";

// Add Multiple captions for multiple images

const ProjectAlbums = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [galleryItemId, setGalleryItemID] = useState(null);
  const [showAddImg, setShowAddImg] = useState(false);

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const { loading, creating, created } = useSelector((state) => state.records);
  const { albumsData } = useSelector((state) => state.records?.current);
  const userRoles = roles && roles.map(({ name }) => name);

  const navigate = useNavigate();
  const handleClose = () => { getData(); setShow(false) };
  const handleShow = () => { setShow(true) };
  const handleCloseAddImg = () => { setShowAddImg(false) }
  const handleShowAddImg = (galleryId) => { setGalleryItemID(galleryId); setShowAddImg(true) }
  const formValues = useSelector((state) => state.form.values);

  useEffect(() => {
    getData();
  }, [id, created]);

  const getData = () => {
    dispatch({ type: getRecords.type, payload: { url: `/Gallery/GetAll/?ProjectId=${id}`, appendCurrentReducerKey: "albumsData" } });
  };

  const handleDelete = (galleryId) => {

    confirmAlert({
      message: "Are you sure You Want To Delete This Album ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            console.log("🚀 ~ handleDelete ~ galleryId:", galleryId)

            dispatch({ type: deleteRecord.type, payload: { id: galleryId, url: `/Gallery` } })
            getData();
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name",
    });


  }

  const handleAddAlbum = () => {

    const formValues = {
      title: {
        name: "title",
        value: title
      },
      "projectId": {
        name: "projectId",
        value: id
      }
    };
    dispatch({ type: createRecord.type, payload: { ...formValues, url: '/Gallery' } })
    setTitle('');

    handleClose();
  }




  const handleSubmitAddImg = () => {

    const GalleryId = {
      name: "GalleryId",
      value: galleryItemId
    }

    const updatedParams = {
      ...formValues,
      GalleryId
    }

    console.log("🚀 ~ handleSubmitAddImg ~ updatedParams:", updatedParams)

    dispatch({
      type: createRecord.type,
      payload: {
        ...updatedParams,
        url: "/Gallery/ImportPictures",
        headers: { "Content-Type": "multipart/form-data" },
      },
      
    });
  
    handleCloseAddImg();
    getData();
  };

  return (
    <>
      <Loader loading={loading || creating} />
      <hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>
      <div className="sub d-md-flex justify-content-between align-items-center mb-3 mt-3 px-2">
        <div className="right d-md-flex py-2 ">
          <h4 className="font-weight-bold text-black">Albums</h4>
        </div>
        {canView([USER_ROLE.ADMIN,USER_ROLE.SUPER_ADMIN], userRoles) && <div className="right text-right ">
          <Button variant="primary" onClick={handleShow} className="d-flex flex-row  justify-center align-items-center">
            <Icon.PlusCircle /><span className="ml-1">Add Album</span>
          </Button>
        </div>}
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Create Gallery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <bootstrapForm>
                <bootstrapForm.Group controlId="formGalleryTitle">
                  <bootstrapForm.Label>Gallery Title</bootstrapForm.Label>
                  <bootstrapForm.Control
                    type="text"
                    placeholder="Enter gallery title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </bootstrapForm.Group>
                <div className="text-center">
                  <Button variant="primary" className="mt-3" onClick={handleAddAlbum} >
                    Submit
                  </Button>
                </div>
              </bootstrapForm>
            </Modal.Body>
          </Modal>
        </>
        <>
          <Modal show={showAddImg} onHide={handleCloseAddImg}>
            <Modal.Header closeButton>
              <Modal.Title>Add Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                formData={[
                  // {
                  // label: "Upload File",
                  //   inputType: "file",
                  //   inForm: true,
                  //   onChangeMethod: "onFileChange",
                  //   name: "Attachments[0].file",
                  //   id: "attachment.Attachment",
                  //   className: "mx-auto",
                  //   wrapperClass: "col-12",
                  // },
                  {
                    category: "projectDetail",
                    label: "Upload Images",
                    placeholder: "",
                    name: "attachments",
                    id: "attachment.Attachment",
                    onChangeMethod: "onMultiFileChange",
                    // onChangeMethod: "onFileChange",
                    inForm: true,
                    wrapperClass: "col-12",
                    inputType: "image-caption"
                  },
                  // {
                  //   inputType: "text",
                  //   label: "Caption",
                  //   placeholder: "Enter caption",
                  //   name: "attachments.caption",
                  //   id: "attachment.caption",
                  //   inForm: true,
                  //   wrapperClass: "col-12",

                  // },

                ]}
                // onSubmit={() => handleSubmitAddImg()}
                withValidation={false}
                formValidation={[]}
                extraInputClass=""
              />
           
              <div className="text-center mt-3 ">
                <Button variant="primary" type="submit" onClick={handleSubmitAddImg}>
                  Submit Gallery
                </Button>
                {/* <Button variant="primary" type="submit" onClick={handleAddAttachment}>
                 Add Attachment
                </Button> */}
              </div>

            </Modal.Body>
          </Modal>

        </>
      </div>
      {albumsData?.data?.items && albumsData?.data?.items.length > 0 ? (
        albumsData?.data?.items.map((project, projectIndex) => {
          const { attachments, title, galleryId } = project;
          const baseUrlData = `/projects/albums/${id}/album/${galleryId}`;
          const featuredImage = attachments.length > 0 ? attachments[0] : null;

          return (
            <div key={projectIndex} className="mb-5 mt-3">
              <hr />
              <div className="d-flex flex-row justify-content-between ">

                <div className="d-flex align-items-center">
                  <img src={MAP_ICON} alt="map" className="map-icon" />
                  <div className="ml-2">
                    <p className="text-primary font-weight-bold mb-0">
                      {title ?? "No Title"}
                    </p>
                  </div>
                </div>

                <div className="">
                  <Button variant="primary" className="mx-2" onClick={() => { handleShowAddImg(galleryId) }}>Add Images</Button>
                  <Button variant="danger" className="mx-2" onClick={() => { handleDelete(galleryId) }}>Delete Album</Button>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div className="d-flex gap-3 ">
                  {attachments?.length > 0 ?
                    attachments.map(({ url, caption }, index) =>
                      index < 4 ? (
                        <div
                          key={index}
                          className="m-1"
                          onClick={() =>
                            navigate(baseUrlData, {
                              state: { imageList: attachments, index },
                            })
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex flex-column">
                            <img src={`${baseUrl}${url}`} alt={caption} width={150} height={150} className="border" style={{ objectFit: 'cover' }} />
                            <span style={{ fontSize: "12px" }}>{caption && truncateText(caption, 20)}</span>
                          </div>
                        </div>
                      ) : null
                    ) :

                    <p className="font-weight-bold text-primary my-5 px-2  " style={{ textAlign: "center", fontSize: "25px", }}>No images Added Yet</p>

                  }
                  {(attachments?.length) > 4 ?
                    <div className="d-flex flex-row justify-content-start align-items-start">
                      <div
                        className="border d-flex flex-column  justify-content-center align-items-center rounded cursor-pointer"
                        width={150} style={{ objectFit: 'cover', height: '150px' }}
                        onClick={() =>
                          navigate(baseUrlData, { state: { imageList: attachments, index: 0 } })
                        }

                      >
                        <span className=" font-weight-bold">{attachments.length - 4}</span>
                        <p className="px-3">More photos</p>
                      </div>
                    </div>
                    : null}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p>No albums available</p>
      )}

    </>
  );
};

export default ProjectAlbums;
