import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import {
  clearForm,
  createRecord,
  formatRecords,
  putRecord,
} from "../../redux/reducers";
import ToolTip from "../tooltip/Tooltip";
import Form from "../form/Form";
import * as Icon from "react-bootstrap-icons";
import DeleteRow from "../../pages/DeleteRow";
import Loader from "../loader/loader";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import Table from "../table/Table";

const columnsData = [
  {
    name: "Intervention Logic Name",
    selector: (row) => (
      <ToolTip label={`${row.interventionLogicName}`}>
        {row.interventionLogicName}
      </ToolTip>
    ),
  },

  {
    name: "Intervention Logic Type",
    selector: (row) => (
      <ToolTip label={`${row.interventionLogicType}`}>
        {row.interventionLogicType}
      </ToolTip>
    ),
  },
];

const AddExpectedResult = () => {
  let { id } = useParams();
  const projectId = id;
  const dispatch = useDispatch();
  const { formFields } = useSelector((state) => state.fields);
  const { created, loading } = useSelector((state) => state.records);
  const { expectedResults = {} } = useSelector(
    (state) => state.records?.current
  );
  const [activityModalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState({});

  //by default clear previous step form data
  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, []);

  useEffect(() => {
    getExpectedResults();
  }, [created]);

  function getExpectedResults() {
    dispatch({
      type: "records/getRecords",
      payload: {
        url: `/InterventionLogic/GetExpectedResults?ProjectId=${projectId}`,
        appendCurrentReducerKey: "expectedResults",
      },
    });
  }

  const expectedResultsColumns = [
    ...columnsData,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Edit">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => openModal(row)}
            />
          </ToolTip>
          <DeleteRow row={row} url="/InterventionLogic" />
        </>
      ),
    },
  ];

  const onAdd = (params) => {
    let formData = {
      ...params,
      interventionLogicType: { value: INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT },
      projectId: { value: projectId },
    };
    dispatch({
      type: createRecord.type,
      payload: { ...formData, url: "/InterventionLogic" },
    });
  };

  function toggleModal() {
    setModalIsOpen(!activityModalIsOpen);
  }

  function openModal(row = null) {
    toggleModal();
    if (row) {
      setCurrentRow(row);
      dispatch({ type: formatRecords.type, payload: { ...row } });
    }
  }

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  function formatFields() {
    let fields = getFieldsByCategory(formFields, "logframe");
    return fields.map((field) => {
      if (field.name == "specificObjective")
        return { ...field, url: field.url + projectId };
      return field;
    });
  }

  function modalFormatFields() {
    let fields = getFieldsByCategory(formFields, "logframe-update");
    return fields.map((field) => {
      if (field.name == "specificObjective")
        return { ...field, url: field.url + projectId };
      return field;
    });
  }

  function closeActivityModal(row = null) {
    toggleModal();
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function postData(params) {
    let formData = { ...params, Id: { value: currentRow?.id } };
    dispatch({
      type: putRecord.type,
      payload: { ...formData, url: "/InterventionLogic" },
    });

    toggleModal();
  }
  return (
    <div className="position-relative">
      <Loader loading={loading} theme="light" />
      <Form
        formData={formatFields()}
        onSubmit={(params) => onAdd(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
      <hr />
      <div>
        <div className="font-weight-bold">Expected Results List</div>
        <Table
          columns={expectedResultsColumns}
          data={expectedResults?.data?.items}
        />
        <Modal
          isOpen={activityModalIsOpen}
          ariaHideApp={false}
          overlayClassName="modal-sm "
          contentLabel="Expected Results"
        >
          <Icon.XCircle onClick={closeActivityModal} className="action-icons" />
          <h4>Expected Results</h4>
          <Form
            formData={modalFormatFields() ?? []}
            onSubmit={(params) => postData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal>
      </div>
    </div>
  );
};
export default AddExpectedResult;
