import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import * as Icon from "react-bootstrap-icons";
import { baseUrl } from "../../../redux";
import Form from "../Form";

const UploadImagesCaptions = ({
  className = "",
  value,
  accept = "image/*",
  multiple = true,
  ...props
}) => {
  const [images, setImages] = useState([...value]);

  useEffect(() => {
    setImages([...value]);
  }, [value]);

  const handleDelete = (index) => {
    let updatedFiles = [...value];
    updatedFiles.splice(index, 1);
    props.onChange({
      target: { name: props.name, isDeleted: true, updatedFiles },
    });
  };

  return (
    <>
      <input
        {...props}
        className={className}
        type="file"
        accept={accept}
        multiple={multiple}
      />
      <span className="d-flex flex-column mt-2">
        {images &&
          images.map((img, index) => (
            <>
              <div key={index} className="d-flex flex-row mr-2 p-2">
                {img?.url && (
                  <img
                    style={{
                      height: "80px",
                      width: "80px",
                      objectFit: "cover",
                    }}
                    src={baseUrl + img?.url}
                    alt="image"
                  />
                )}
                <div className="">{img?.name}</div>

                <Icon.Trash
                  onClick={() => handleDelete(index)}
                  size="16"
                  className="action-icons"
                />
              </div>
              <div>
                {/* <input type="text"  name={ "caption"}  id ={"attachment.Caption"} className="form-control" placeholder="Caption" /> */}
                <Form
                  formData={[
                    {
                      inputType: "text",
                      placeholder: "Enter caption for " + img?.name,
                      name: `attachments[${index}].caption`,
                      id: `attachments[${index}].caption`,
                      inForm: true,
                      wrapperClass: "col-12",
                    },
                  ]}
                  // onSubmit={() => handleSubmitAddImg()}
                  withValidation={false}
                  formValidation={[]}
                  extraInputClass=""
                />
              </div>
            </>
          ))}
      </span>
    </>
  );
};

export default UploadImagesCaptions;
