import React, { useState, useEffect } from "react";
import "./charts.scss";
import { ReactComponent as SvgChart } from "./../../assets/images/sdg_fin.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";

const SDGChart = ({ projectId }) => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(1);
  const [selectedSDG, setSelectedSDG] = useState(null);
  const { sdgChartData } = useSelector((state) => state.records.current);
  const data = sdgChartData?.data?.items || [];

  useEffect(() => {
    const fetchData = () => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: projectId
            ? `/Dashboard/GetSDGProjectCount?Project.Id=${projectId}`
            : "/Dashboard/GetSDGProjectCount",
          appendCurrentReducerKey: "sdgChartData",
        },
      });
    };
    setTimeout(fetchData, 1000);
  }, [projectId, dispatch]);

  const getIdFromName = (name) => {
    if (!name) return null;
    const match = name.match(/\d+|[^\d_.-]+/g);
    return match ? match[1] : null;
  };

  const handleChartClick = (event) => {
    const contentId = event.target.parentNode.id;
    const id = getIdFromName(contentId);
    if (id) {
      const sdg = data.find(({ sdgNo }) => sdgNo === parseInt(id));
      setSelectedId(id);
      setSelectedSDG(sdg);
      if (!sdg) {
        setSelectedSDG({
          id: 0,
          sdgNo: id,
          colorCode: "gray",
          name: "No Data Found",
          count: 0,
        });
      }
    }
  };

  const getEmptySdgs = () => {
    return data
      .filter(({ count }) => count === 0)
      .map(({ sdgNo }) => `sdg-${sdgNo}`)
      .join(" ");
  };

  const handleClose = () => {
    setSelectedSDG(null);
  };

  const getDisabledSdg = () => {
    const disabledSdg = [];
    const counts = 17;
    if (data) {
      for (let i = 1; i <= counts; i++) {
        const sdgNo = data.some(({ sdgNo }) => sdgNo == i);
        if (!sdgNo) {
          disabledSdg.push(`#sdg-${i} path{fill:gray}`);
        }
      }
    }
    return disabledSdg.join(" ");
  };

  return (
    <div className="sdg-wrapper">
      <style>{getDisabledSdg()}</style>
      <SvgChart className={getEmptySdgs()} onClick={handleChartClick} />
      {selectedSDG && (
        <div
          className="circle-pop intro"
          style={
            selectedSDG?.count > 0
              ? { background: `#${(selectedSDG?.colorCode).trim()}` }
              : {}
          }
        >
          <span onClick={handleClose} className="close text-center">
            X
          </span>
          <div className="circle-content">
            <div className="text-content text-center w-100">
              <p className="heading">{selectedSDG.name}</p>
              <p className="totalCalculate">
                Total Projects: {selectedSDG.count}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SDGChart;
