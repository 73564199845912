import React, { useEffect } from "react";
import "./ActivityMap.scss";
import {
  MapContainer,
  TileLayer,
  Marker,
  ZoomControl,
  Polygon,
  Pane,
  Polyline,
  Tooltip,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import geoJson from "../../../assets/json/shapes.json";
import { RecordCircle } from "react-bootstrap-icons";
import Label from "../../ui/Label";
import pinGreen from "../../../assets/pin-green.png";
import pinBlue from "../../../assets/pin-blue.png";
import pinRed from "../../../assets/pin-red.png";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import MarkerClusterGroup from "react-leaflet-cluster";

const ActivityMap = () => {
  const dispatch = useDispatch();
  const { ActivityCountByThematicAreaMap = [] } = useSelector(
    (state) => state?.records?.current
  );
  console.log(
    "🚀 ~ ActivityMap ~ ActivityCountByThematicAreaMap:",
    ActivityCountByThematicAreaMap
  );

  const pinGreenIcon = new L.Icon({
    iconUrl: pinGreen,
    iconRetinaUrl: pinGreen,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(24, 24),
  });

  const pinBlueIcon = new L.Icon({
    iconUrl: pinBlue,
    iconRetinaUrl: pinBlue,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(24, 24),
  });

  const pinRedIcon = new L.Icon({
    iconUrl: pinRed,
    iconRetinaUrl: pinRed,
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(24, 24),
  });

  const markerIcon = {
    pending: pinRedIcon,
    ongoing: pinBlueIcon,
    complete: pinGreenIcon,
  };

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetGeographicalAreaByActivities`,
        isLocal: false,
        appendCurrentReducerKey: "ActivityCountByThematicAreaMap",
      },
    });
  }, [dispatch]);

  // Filter out invalid coordinates
  // const cityCoordinates = ActivityCountByThematicAreaMap
  const cityCoordinates =
    ActivityCountByThematicAreaMap?.data?.items
      ?.filter((item) => item?.latitude && item?.longitude) // Filter out invalid coordinates
      ?.map((item) => ({
        name: item?.thematicAreaName,
        areaName:item?.tehsil,
        activityCount: item?.activityCount,
        beneficiariesCount: item?.beneficiariesCount,
        totalAmount: item?.totalAmount,
        coordinates: [item?.latitude, item?.longitude],
        status: item?.status ?? "pending",
      })) || [];

  console.log("🚀 ~ ActivityMap ~ cityCoordinates:", cityCoordinates);

  return (
    <div className="bg-white shadow-sm activity-map-container h-100">
      <div className="d-flex justify-content-between w-100">
        <Label>Activity Map</Label>

        <div className="d-flex align-items-center">
          <div className="mx-1">
            <RecordCircle color="orange" />
            <text className={`mx-1 orange-dot`}>Pending</text>
          </div>
          <div className="mx-1">
            <RecordCircle color="skyblue" />
            <text className="mx-1 sky-blue">Ongoing</text>
          </div>
          <div className="mx-1">
            <RecordCircle color="green" />
            <text className="mx-1 green-dot">Complete</text>
          </div>
        </div>
      </div>
      <MapContainer
        className="map"
        center={[30.1068, 69.4755]}
        zoom={5.5}
        style={{ height: "90%", zIndex: 99 }}
        scrollWheelZoom={false}
        zoomControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position="bottomright" />
        <MarkerClusterGroup chunkedLoading>
          {cityCoordinates.map((city, index) => (
            <Marker
              key={index}
              position={city?.coordinates}
              icon={markerIcon[city?.status]}
            >
              {/* <Popup>{city?.name}</Popup> */}
              <Tooltip>
                <div className="d-flex flex-column gap-1 p-2">
                <div>
                    
                    <span className="px-1">Area Name:</span>
                    <span className="font-weight-bold">{city?.areaName}</span>
                  </div>
                  <div>
                    
                    <span className="px-1">Sector Name:</span>
                    <span className="font-weight-bold">{city?.name}</span>
                  </div>
                  <div>
                    
                    <span className="px-1">Number of Activities:</span>
                    <span className="font-weight-bold">
                      {city?.activityCount}
                    </span>
                  </div>
                  <div>
                    
                    <span className="px-1">Number of Beneficiaries:</span>
                    <span className="font-weight-bold">
                      {city?.beneficiariesCount}
                    </span>
                  </div>
                  <div>
                    
                    <span className="px-1">Number of Non-Beneficiaries:</span>
                    <span className="font-weight-bold">
                      {city?.totalAmount}
                    </span>
                  </div>
                </div>
              </Tooltip>
            </Marker>
          ))}
        </MarkerClusterGroup>

        {geoJson.features.map((feature, index) => {
          if (feature.geometry.type === "MultiPolygon") {
            const multiPolygons = feature.geometry.coordinates.map(
              (coordinate) =>
                coordinate[0].map((coordinate) => [
                  coordinate[1],
                  coordinate[0],
                ])
            );
            return multiPolygons.map((coordinates, index) => (
              <Polygon
                key={index.toString()}
                positions={coordinates}
                pathOptions={feature.properties.Style}
              />
            ));
          } else if (feature.geometry.type === "LineString") {
            feature.geometry.coordinates.map((coordinates, index) => (
              <Polyline
                key={index.toString()}
                positions={coordinates.map((coord) => [coord[1], coord[0]])}
                pathOptions={feature.properties.Style}
              />
            ));
          }
          return null;
        })}
      </MapContainer>
    </div>
  );
};

export default ActivityMap;
