import ToolTip from "../../components/tooltip/Tooltip";
import { truncateText } from "../../utils/util";

const ComplaintResponseColumns = [
  {
    name: "Complainant Level Of Satisfaction",
    selector: (row) => row?.complainantLevelOfSatisfaction,
  },
  {
    name: "Mode Of Responding",
    selector: (row) => row?.modeOfResponding,
  },
  {
    name: "Comments",
    selector: (row) => <ToolTip label={`${row?.comments}`}>{truncateText(row?.comments ?? "", 20)}</ToolTip>,
  },
  {
    name: "Appeal Response",
    selector: (row) => <ToolTip label={`${row?.appealResponse}`}>{row?.appealResponse}</ToolTip>,
  },
];

export default ComplaintResponseColumns;
