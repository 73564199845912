import React, { useEffect } from "react";
import "./DashboardMainPage.scss";
import Chart from "react-apexcharts";
import KeyValueCard from "./DataCard";
import AgeandGenderView from "./AgeandGenderView";
import CurrentProjectsView from "./CurrentProjectsView";
import SDGChart from "../../chart/SDGChart";
import ReacentActivitiesGallery from "./ReacentActivitiesGallery";
import FundsDonarDistribution from "./FundsDonarDistribution";
import SectorWiseProgress from "./SectorWiseProgress";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import GenderAgeSegreBenficiaries from "./GenderAgeSegreBenficiaries";
import TargetVsAchievedBenif from "./TargetVsAchievedBenif";
import SectorWiseProjects from "./SectorWiseProjects";
import { MainDashboardPagefiltersItems } from "../../../utils/util";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";

const DashboardMainPage = () => {
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();

  const {
    topInfo,
    totalBeneficiaries,
    activitiesCountData,
    fundingProjectCountData,
    OverlappingBeneficiaries = {
      data: { items: [{ totalBeneficiaries: 0, overlappingBeneficiaries: 0 }] },
    },
    projectMVRStatusCount = { data: { items: [] } },
  } = useSelector((state) => state.records?.current);

  const dashboardData = [
    ...MainDashboardPagefiltersItems,
    {
      url: `/Beneficiary/GetAll`,
      appendCurrentReducerKey: "totalBeneficiaries",
    },
    {
      url: `/Dashboard/GetFundingOrganizationCount`,
      appendCurrentReducerKey: "fundingProjectCountData",
    },
  ];

  const topInfoData = [
    {
      data: topInfo?.data?.items[0]?.completedProjects ?? 0,
      label: "Completed Projects",
    },
    {
      data: topInfo?.data?.items[0]?.ongoingProjects ?? 0,
      label: "Ongoing Projects",
    },
    {
      data: totalBeneficiaries?.data?._meta?.totalCount ?? 0,
      label: "Total Beneficiaries",
    },
    {
      data: activitiesCountData?.data?.items[0]?.activityCount ?? 0,
      label: "Ongoing Activities",
    },

    {
      data: fundingProjectCountData?.data?.items[0]?.coreProjectsCount ?? 0,
      label: "Core Funded Projects",
    },
    {
      data: fundingProjectCountData?.data?.items[0]?.donorProjectsCount ?? 0,
      label: "Donor Funded Projects",
    },
  ];

  const MVRseries = [
    {
      name: "Pending",
      data: projectMVRStatusCount.data.items.map(
        (item) => item?.pendingCount || 0
      ),
    },
    {
      name: "Ongoing",
      data: projectMVRStatusCount.data.items.map(
        (item) => item?.ongoingCount || 0
      ),
    },
    {
      name: "Completed",
      data: projectMVRStatusCount.data.items.map(
        (item) => item?.completedCount || 0
      ),
    },
  ];

  const MVRoptions = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10%",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: projectMVRStatusCount.data.items.map(
        (item) => item?.projectName || "Unknown"
      ),
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetX: 40,
      markers: {
        radius: 50,
      },
    },
    grid: {
      show: false,
    },
  };

  useEffect(() => {
    dashboardData?.map(({ appendCurrentReducerKey, url }) => {
      dispatch({
        type: getRecords.type,
        payload: { url: url, appendCurrentReducerKey: appendCurrentReducerKey },
      });
    });
  }, []);

  return (
    <>
      <KeyValueCard cardsData={topInfoData ?? []} />
      <div class="container-fluid p-0 my-4">
        <div class="row px-0 g-3 row-container">
          <div class="col-xl-8 margin">
            <AgeandGenderView />
          </div>
          <div class="col-xl-4">
            <CurrentProjectsView />
          </div>
        </div>

        <div class="row px-0 g-3 mt-4 row-container">
          <div class="col-xl-4 margin">
            <div className="h-100 bg-white itemContainerLast shadow-sm">
              <Label>SDG Wise Distribution</Label>
              <SDGChart />
            </div>
          </div>

          <div class="col-xl-4 margin">
            <div className="h-100 bg-white itemContainerLast shadow-sm">
              <Label>Overlapping Beneficiaries</Label>
              {/* <OverlappingBeneficiaries /> */}
              <SimplePieChart
                type="donut"
                width={isMobileView ? 240 : 360}
                height={isMobileView ? 240 : 360}
                series={[
                  OverlappingBeneficiaries.data.items[0]?.totalBeneficiaries ||
                    0,
                  OverlappingBeneficiaries.data.items[0]
                    ?.overlappingBeneficiaries || 0,
                ]}
                labels={["Total Beneficiaries", "Overlapping Beneficiaries"]}
                position={"bottom"}
                colors={["#554B86", "#0E7BB6"]}
                legendOptions={{
                  show: true,
                }}
              />
            </div>
          </div>
          <div class="col-xl-4 margin">
            <ReacentActivitiesGallery />
          </div>
        </div>

        <div class="row px-3 g-3 mt-4 row-container">
          <div
            className="bg-white col-xl-12 shadow-sm p-3 h-100 "
            style={{ borderRadius: "12px" }}
          >
            <h5 className="chart-heading">Project MVR Status</h5>
            <Chart
              options={MVRoptions}
              series={MVRseries}
              type="bar"
              height={300}
            />
          </div>
        </div>
        <div class="row px-0 g-3 mt-4 row-container">
          <div class="col-xl-6 margin">
            <div className="h-100 bg-white itemContainerLast shadow-sm">
              <Label>Sector Wise Projects</Label>
              <div className="mt-3">
                <SectorWiseProjects />
              </div>
            </div>
          </div>
          <div class="col-xl-6 margin">
            <div className="h-100 bg-white itemContainerLast shadow-sm">
              <Label>Sector Wise Progress</Label>
              <SectorWiseProgress />
            </div>
          </div>
        </div>
      </div>
      <div class="row px-0 g-3 mt-4 row-container">
        <div class="col-xl-6 margin">
          <div className="h-100 bg-white itemContainerLast shadow-sm">
            <Label>Fund's Donor Distribution</Label>
            <div className="mt-5">
              <FundsDonarDistribution />
            </div>
          </div>
        </div>

        <div class="col-xl-6 margin">
          <div className="h-100 bg-white itemContainerLast shadow-sm">
            <Label>Target vs Achieved Beneficaries</Label>
            <TargetVsAchievedBenif />
          </div>
        </div>

        {/* <GenderAgeSegreBenficiaries /> */}
        {/* Above chart should be Added after getting Required API */}
      </div>
    </>
  );
};

export default DashboardMainPage;
