const BeneficiaryColumns = [
  {
    name: "Beneficiary",
    selector: (row) => row?.name,
  },
  {
    name: "Head of Household Name",
    selector: (row) => row?.headOfHouseHoldName,
  },
  {
    name: "Relation with Beneficiary",
    selector: (row) => row?.relationshipWithBeneficiary,
  },
  {
    name: "Age",
    selector: (row) => row?.age,
  },
  {
    name: "CNIC/ POR No.",
    selector: (row) => row?.cnic,
  },
  {
    name: "Phone No",
    selector: (row) => row?.phoneNo,
  }
];

export default BeneficiaryColumns;
