import React, { useEffect } from "react";
import "./Complaint.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedDataByArray, truncateText } from "../../../utils/util";
import { getRecords } from "../../../redux/reducers";
import ToolTip from "../../tooltip/Tooltip";

const ProjectsListForComplaint = () => {
  const dispatch = useDispatch();
  const { projectListData } = useSelector((state) => state.records?.current);

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetProjectStats`,
        appendCurrentReducerKey: "projectListData",
      },
    });
  }, []);

  return (
    <div className="bg-white shadow-sm project-list-container h-100 overflow-auto">
      <div className="mb-4">
        <Label>Projects</Label>
      </div>
      {projectListData?.data?.items?.map((data, index) => {
        return (
          <div key={index.toString()} className="d-flex flex-column mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p class="lh-1 mb-0 heading w-75">
                <ToolTip label={`${data?.project ?? ""}`}>
                  
                  {truncateText(data?.project ?? "", 30)}
                </ToolTip>
              </p>
              <div
                class="progress w-75"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  style={{
                    width: `${data?.percentage ?? 0}`,
                    background:
                      parseFloat(data?.percentage) > 50 ? "#84E0BE" : "#0075B6",
                    borderRadius: "10px",
                  }}
                ></div>
              </div>
              <p class="lh-2 ml-1 mb-0 heading">{data?.percentage}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectsListForComplaint;
