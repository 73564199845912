import React, { useEffect, useState } from "react";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";

const SectorWiseProjects = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 425px)");
  const { sectorWiseProjects = { data: { items: [] } } } = useSelector(
    (state) => state.records.current || {}
  );

  // useEffect(() => {
  //   setTimeout(() => {
  //     getData();
  //   }, 200);
  // }, []);

  const getData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/ProjectCountByThematicArea`,
        appendCurrentReducerKey: "sectorWiseProjects",
      },
    });
  };

  const getSectorWiseProjectLabels = () => {
    const labels = [];
    sectorWiseProjects.data.items.forEach((item) => {
      labels.push(item?.thematicAreaName || "Unknown");
    });
    return labels;
  };

  const getSectorWiseProjectData = () => {
    const data = [];
    sectorWiseProjects.data.items.forEach((item) => {
      data.push(item?.projectCount || 0);
    });
    return data;
  };

  const sectorWiseProjectChartData = {
    values: getSectorWiseProjectData(),
    labels: getSectorWiseProjectLabels(),
  };

  return (
    <div className="fundsDonarDistribution">
      <div className="d-flex justify-content-center ">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 320}
          height={isMobileView ? 240 : 320}
          series={sectorWiseProjectChartData?.values}
          labels={sectorWiseProjectChartData?.labels}
          position={"bottom"}
          legendOptions={{
            show: true,
          }}
        />
      </div>
    </div>
  );
};

export default SectorWiseProjects;
