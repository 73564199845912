import React, { useEffect, useState } from "react";
import "./DataCard.scss";
const DataCard = ({ cardsData = [] }) => {
  return (
    <>
      {cardsData?.length > 0 && (
        <div className="top-info-view w-100 h-100 bg-white shadow-sm py-3 px-4 mt-4">
          {cardsData?.map((card, index) => (
            <>
              <div className="text-container" key={index}>
                <span className="title pb-2">{card?.label ?? ""}</span>
                <h3 className={`value`}>{card?.data ?? 0}</h3>
              </div>
              {cardsData?.length - 1 !== index &&   <div className="separate-bar"></div>}
            </>
          ))}
        </div>
      )}
    </>
  );
};

export default DataCard;
