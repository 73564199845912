export const leftMenu = [
  {
    label: "Country",
    path: "/definitions/country"
  },
  {
    label: "Province",
    path: "/definitions/province"
  },
  {
    label: "District",
    path: "/definitions/district"
  },
  {
    label: "Tehsil",
    path: "/definitions/tehsil"
  },
  {
    label: "Union Council",
    path: "/definitions/union-council"
  },
  {
    label: "Thematic Area",
    path: "/definitions/thematic-area"
  },
  {
    label: "Vulnerability",
    path: "/definitions/vulnerability"
  },
  {
    label: "Sustainable Development Goal",
    path: "/definitions/sustainable-development-goal"
  },
  {
    label: "Community Type",
    path: "/definitions/community-type"
  },
  {
    "label": "Periodicity",
    "path": "/definitions/periodicity"
  },
  {
    "label": "Means Of Verification",
    "path": "/definitions/means-of-verification"
  },
  {
    "label": "Assumption Or Risk",
    "path": "/definitions/assumption-or-risk"
  },
  {
    "label": "Funding Organization",
    "path": "/definitions/funding-organization"
  },
  {
    "label": "Implementing Partner",
    "path": "/definitions/implementing-partner"
  }
];
