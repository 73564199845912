import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/loader/loader";
import { formatRecords, getRecords } from "../../redux/reducers";
import FeedbackResolutionModal from "../../components/complain/FeedbackResolutionModal";
import ComplaintResponseModal from "../../components/complain/ComplaintResponseModal";
import MdIcon from "../../components/icon/MdIcon";
import ToolTip from "../../components/tooltip/Tooltip";

import Table from "../../components/table/Table";
import ComplaintResponseColumns from "../../assets/columns/ComplaintResponseColumns";
import ComplaintFeedbackColumns from "../../assets/columns/ComplaintFeedbackColumns";
import usePageMeta from "../../hooks/usePageMeta";
import ComplaintAppealModalx from "../../components/complain/ComplaintAppealModal";
import { getFormatedDate } from "../../Utility";
import { truncateText } from "../../utils/util";

const Complaint = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);
  const [state, setState] = useState({
    feedbackModalIsOpen: false,
    responseModalIsOpen: false,
    appealModalIsOpen: false,
    selectedFeedback: null,
    selectedResponse: null,
  });

  useEffect(() => {
    getComplaint();
    if (created) {
      setState({
        ...state,
        feedbackModalIsOpen: false,
        feedbackIsEdit: false,
        responseModalIsOpen: false,
        appealModalIsOpen: false,
      });
    }
  }, [id, created]);

  useEffect(() => {
    setMeta({ title: `Complainant: ${data?.complainantName}` });
  }, [data?.complainantName]);

  const getComplaint = () => {
    dispatch({
      type: getRecords.type,
      payload: { url: `/Complaint/${id}` },
    });
  };

  const onOpenFeedbackResponseModal = (row, isEdit) => {
    setState({
      ...state,
      responseModalIsOpen: true,
    });
    dispatch({
      type: formatRecords.type,
      payload: {
        ...row?.complaintResponse,
        complaint: row?.complaint,
        complaintFeedback: { id: row?.id },
        isEdit,
      },
    });
  };

  const onOpenAppealModal = (row) => {
    setState({ ...state, appealModalIsOpen: true, selectedResponse: row });
  };

  const onEditFeedback = (row) => {
    setState({
      ...state,
      feedbackModalIsOpen: true,
      feedbackIsEdit: true,
    });
    dispatch({ type: formatRecords.type, payload: row });
  };

  const feedbackColumns = [
    ...ComplaintFeedbackColumns,
    {
      name: "Response",
      minWidth: "160px",
      selector: (row) => (
        <>
          <ToolTip
            label={`${row?.canCreateResponse ? "Add" : "Edit"} Response`}
          >
            {(row?.canCreateResponse || row?.canEditResponse) && (
              <button
                className="btn btn-info btn-sm"
                onClick={() =>
                  onOpenFeedbackResponseModal(row, row?.canEditResponse)
                }
              >
                <MdIcon
                  iconName={"MdOutlineQuestionAnswer"}
                  size={"14px"}
                  color="#fff"
                />
                <span className="pl-1">
                  {row?.canCreateResponse ? "Add" : "Edit"} Response
                </span>
              </button>
            )}
          </ToolTip>
        </>
      ),
    },
    {
      name: "Feedback",
      selector: (row) => (
        <>
          <ToolTip label="Edit Feedback">
            {row?.canEditFeedback && (
              <button
                className="ml-2 btn btn-primary btn-sm"
                onClick={() => onEditFeedback(row)}
              >
                <MdIcon iconName={"MdEdit"} size={"14px"} color="#fff" />
              </button>
            )}
          </ToolTip>
        </>
      ),
      minWidth: 200,
    },
  ];

  const responseColumns = [
    ...ComplaintResponseColumns,
    {
      name: "Appeal",
      selector: (row) => (
        <>
          {row?.canCreateAppeal && (
            <ToolTip label="Complaint Feedback">
              <button
                className="btn btn-primary btn-sm"
                onClick={() => onOpenAppealModal(row)}
              >
                <MdIcon
                  iconName={"MdOutlineFeedback"}
                  size={"18px"}
                  color="#fff"
                />
                Appeal
              </button>
            </ToolTip>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <FeedbackResolutionModal
        modalIsOpen={state.feedbackModalIsOpen}
        feedbackIsEdit={state.feedbackIsEdit}
        toggleFeedbackResolutionModal={() =>
          setState({ ...state, feedbackModalIsOpen: false })
        }
      />
      <ComplaintResponseModal
        modalIsOpen={state.responseModalIsOpen}
        toggleComplaintResponseModal={() =>
          setState({ ...state, responseModalIsOpen: false })
        }
      />

      <ComplaintAppealModalx
        modalIsOpen={state.appealModalIsOpen}
        selectedResponse={state.selectedResponse}
        toggleComplaintAppealModal={() =>
          setState({ ...state, appealModalIsOpen: false })
        }
      />

      <Loader loading={loading} />

      {/* Compaint Info */}
      <div className="container my-3">
        <div className="row">
          {/* <div className="col-lg-6">
            <h4 className="pt-1 mb-3">Complaint Detail</h4>
            <div className="row">
              <div className="col-sm-3 col-6 mt-2">
                <h6>Complaint Name</h6>
              </div>
              <div className="col-sm-9 col-6">{data.complainantName}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Complaint Date</h6>
              </div>
              <div className="col-sm-9 col-6">
                {getFormatedDate(data.dateOfComplaint)}
              </div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Gurdian Name</h6>
              </div>
              <div className="col-sm-9 col-6">{data.guardianName}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Cell no</h6>
              </div>
              <div className="col-sm-9 col-6">{data.cellNo}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>CNIC</h6>
              </div>
              <div className="col-sm-9 col-6">{data.cnic}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Complaint Description</h6>
              </div>
              <div className="col-sm-9 col-6">
                {data.descriptionOfComplaint}
              </div>
            </div>
          </div> */}
          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12 mb-4">
                <h4 className="pt-1 mb-3">Complaint Details</h4>
                <div className="row">
                  <div className="col-sm-4 col-6">
                    <h6>Complaint Name</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                    {data.complainantName ?? ""}
                  </div>
                  <div className="col-sm-4 col-6">
                    <h6>Complaint Date</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                  {getFormatedDate(data.dateOfComplaint)}
                  </div>
                  <div className="col-sm-4 col-6">
                  <h6>Gurdian Name</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                  {data.guardianName ?? ""}
                  </div>
                  <div className="col-sm-4 col-6">
                  <h6>Cell no</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                  {data.cellNo}
                  </div>

                  <div className="col-sm-4 col-6">
                  <h6>CNIC</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                  {data.cnic}
                  </div>

                  <div className="col-sm-4 col-6">
                  <h6>Complaint Description</h6>
                  </div>
                  <div className="col-md-8 col-6 mb-2">
                 <ToolTip label={`${data.descriptionOfComplaint}`}> {truncateText(data.descriptionOfComplaint ?? "", 100)}</ToolTip>
                  </div>
                </div>
              </div>

             
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12 mb-4">
                <h4 className="pt-1 mb-3">Complaint Info</h4>
                <div className="row">
                  <div className="col-sm-4 col-6">
                    <h6>Complaint Gender</h6>
                  </div>
                  <div className="col-md-8 col-6">
                    {data.complainantGender == 0 ? "Male" : "Female"}
                  </div>
                  <div className="col-sm-4 col-6">
                    <h6>Mode of Complaint</h6>
                  </div>
                  <div className="col-md-8 col-6">
                    {data.modeOfComplaint?.name}
                  </div>
                  <div className="col-sm-4 col-6">
                    <h6>Nature of Complaint</h6>
                  </div>
                  <div className="col-md-8 col-6">
                    {data.natureOfComplaint?.name}
                  </div>
                  <div className="col-sm-4 col-6">
                    <h6>Type of Complaint</h6>
                  </div>
                  <div className="col-md-8 col-6">
                    {data.typeOfComplainant?.name}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <h4 className="pt-1 mb-3">Complaintant Address</h4>
                <div className="row">
                  <div className="col-sm-4 col-6">
                    <h6>Project</h6>
                  </div>
                  <div className="col-md-8 col-6">{data?.project?.name}</div>
                  <div className="col-sm-4 col-6">
                    <h6>District</h6>
                  </div>
                  <div className="col-md-8 col-6">{data.district?.name}</div>
                  <div className="col-sm-4 col-6">
                    <h6>Tehsil</h6>
                  </div>
                  <div className="col-md-8 col-6">{data.tehsil?.name}</div>
                  <div className="col-sm-4 col-6">
                    <h6>Union Council</h6>
                  </div>
                  <div className="col-md-8 col-6">
                    {data.unionCouncil?.name}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* feedback */}
          <div className="col-12 my-5">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h4 className="me-3">Feedback</h4>
              {data?.canCreateFeedback && (
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() =>
                    setState({ ...state, feedbackModalIsOpen: true })
                  }
                >
                  <MdIcon
                    iconName={"MdOutlineFeedback"}
                    size={"18px"}
                    color="#fff"
                  />
                  Add Feedback
                </button>
              )}
            </div>
            <Table
              columns={feedbackColumns}
              data={data?.feedbacks}
              expandableRows
              expandableRowsComponent={(row) => (
                <Table
                  columns={responseColumns}
                  data={
                    row?.data?.complaintResponse
                      ? [
                          {
                            ...row?.data?.complaintResponse,
                            canCreateAppeal: row?.data?.canCreateAppeal,
                          },
                        ]
                      : []
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Complaint;
