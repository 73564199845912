import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ProgressBar from "../../components/progress-bar/ProgressBar";
import Tooltip from "../../components/tooltip/Tooltip";
import { projectMenu } from "./projectMenu";
import "./Projects.scss";
import usePageMeta from "../../hooks/usePageMeta";
import { getIntProgressValue, truncateText } from "../../utils/util";
import RadialBarAngleCircle from "../../components/chart/RadialBarAngleCircle";
import { getFormatedDate } from "../../Utility";
import { FaArrowCircleLeft } from "react-icons/fa";
const loadImage = (url) => {
  const image = require(url);

  return image;
};

const SingleProject = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const projectData = useSelector((state) => state.records.data);
  const { canEditBeneficiaries, canEditComplaints, canEditProject } =
    projectData;

  const { setMeta } = usePageMeta();

  const getFieldValue = (key) => {
    console.log("🚀 ~ getFieldValue ~ key:", key)
    if (!projectData) return null;
    const field = projectData[key];
    if (typeof field === "object")
      return (
        field && field.map(({ name }) => {
          return <span className="mr-1">{key === "locations" ? name + "," : name}</span>
        })
      );
    else return projectData[key];
  };

  const projectMetaCol1 = [
    {
      label: "Project Manager",
      value: getFieldValue("projectManagers"),
    },
    {
      label: "MEAL Officers",
      value: getFieldValue("mealOfficers"),
    },
    {
      label: "Start Date",
      value: getFormatedDate(getFieldValue("startDate")),
    },
    {
      label: "End Date",
      value: getFormatedDate(getFieldValue("endDate")),
    },
    {
      label: "Funds",
      value: projectData?.funds?.total,
      // "Total: 388,874 EUR 82% SIF (321,940 EUR) 16% SIAPEP (61,394 EUR) 2% Sami Foundation (6,630 EUR)",
    },
    {
      label: "SDG",
      value:
        projectData?.sdGs &&
        projectData?.sdGs.map(({ id, name }, ind) => (
          <Tooltip label={name}>
            <img
              key={id}
              style={{ width: "35px", paddingLeft: "8px" }}
              src={`/images/sdg_icons/icon ${ind + 1}.svg`}
            />
          </Tooltip>
        )),
    },
    {
      label: "Thematic Area",
      value: getFieldValue("thematicAreas"),
    },
  ];

  const projectMetaCol2 = [
    {
      label: "Implementing Partner(s)",
      value: getFieldValue("implementingPartners"),
    },
    {
      label: "Target Groups",
      value: getFieldValue("targetGroups"),
    },
    {
      label: "Location",
      value: getFieldValue("locations"),
    },
    {
      label: "General Objective",
      value: getFieldValue("generalObjectives"),
    },
  ];

  useEffect(() => {
    // dispatch({ type: "records/getRecords", payload: { url: `/project/${id}` } })
    dispatch({
      type: "records/getRecords",
      payload: { url: `/Project/GetProjectDetails?id=${id}` },
    });
  }, []);

  useEffect(() => {
    setMeta({ title: projectData?.title });
  }, [projectData]);

  return (
    <div className="row">
      <div className="col-md-8 project-summary-col">
      <hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>
        <h5 className="text-center font-weight-bold mb-4">Project Summary</h5>
        <div className="row">
          <div className="col-md-6 project-summary-col pr-md-4">
            {projectMetaCol1.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6">{value}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6 pl-md-4">
            {projectMetaCol2.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6 pl-0">{value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex project-menu-wrapper my-5">
          {projectMenu.map(({ label, thumbnail = "", link = "" }, index) => (
            <div className="card" key={index}>
              <Link to={link + "/" + id}>
                <img className="image-responsive" src={thumbnail} alt={label} />
                <label className="text-sm">{label}</label>
              </Link>
            </div>
          ))}
        </div>  
      </div>
      <div className="col-md-4">
        <h5 className="text-center font-weight-bold mb-4">Activities</h5>
        {projectData?.activities && (
          <RadialBarAngleCircle
            className="border border-primary"
            series={projectData?.activities.map((act) =>
              getIntProgressValue(act?.percentage)
            )}
            labels={projectData?.activities.map((act) =>
              truncateText(act?.interventionLogicName, 40)
            )}
          />
        )}
        <h5 className="text-center font-weight-bold mb-4">Indicators</h5>
        <div className="row">
          <div className="col-md-12">
            {projectData.indicators &&
              projectData.indicators.map((indicator, index) => (
                <ProgressBar
                  key={index}
                  value={getIntProgressValue(indicator?.percentage)}
                  limit={4}
                  label={indicator?.interventionLogicName}
                  wrapperClass="mb-2"
                  color={
                    getIntProgressValue(indicator?.percentage) > 90
                      ? "#28a745"
                      : "#0075b6"
                  }
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProject;
