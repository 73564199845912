import genderTypes from "./../../assets/values/genderTypes";
import ageGroupTypes from "./../../assets/values/ageGroupTypes";

const complaintFields = [
  {
    category: "complainant",
    label: "Full Name",
    placeholder: "Enter Your Name",
    name: "complainantName",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "Father Name",
    placeholder: "Enter Father Name",
    name: "guardianName",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "Gender",
    placeholder: "Select Gender",
    name: "complainantGender",
    id: "complainantGender",
    inputType: "async-dropdown",
    options: genderTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "label",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "complainant",
    label: "Age",
    placeholder: "Age",
    name: "ageGroup",
    id: "ageGroup",
    type: "number",
    inForm: true,
    wrapperClass: "col-md-6"
  },
  {
    category: "complainant",
    label: "Phone",
    placeholder: "Enter Phone",
    name: "cellNo",
    type: "number",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 10,
      max: 11,
      required: true,
    },
  },
  {
    category: "complainant",
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    name: "cnic",
    type: "number",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 13,
      max: 13,
      required: true,
    },
  },
  {
    category: "complainant",
    label: "Project",
    placeholder: "Select Project",
    name: "project",
    inputType: "async-dropdown",
    url: "/project/GetAll?DisablePagination=true",
    searchAttrib: "title",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complainant",
    label: "Activity",
    placeholder: "Select Activity",
    name: "activity",
    id: "activity",
    inputType: "async-dropdown",
    url: "/ERActivity/GetActivities", //?ProjectId=3
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "project",
      paramField: "projectId",
      action: "useValue",
    },
  },
  {
    category: "complain-info",
    label: "Type of Complaint",
    placeholder: "Select Type of Complaint",
    name: "typeOfComplainant",
    id: "typeOfComplainant",
    inputType: "async-dropdown",
    url: "/TypeOfComplainant/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Nature of Complaint",
    placeholder: "Select Nature of Complaint",
    name: "natureOfComplaint",
    id: "natureOfComplaint",
    inputType: "async-dropdown",
    url: "/NatureOfComplaint/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Complaint Mode",
    placeholder: "Select Complaint Mode",
    name: "modeOfComplaint",
    id: "modeOfComplaint",
    inputType: "async-dropdown",
    url: "/ModeOfComplaint/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Acknowledgement Of Complaint",
    placeholder: "Select Acknowledgement",
    name: "acknowledgementOfComplaint",
    id: "acknowledgementOfComplaint",
    inputType: "async-dropdown",
    url: "/AcknowledgementOfComplaint/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Date of Complain",
    placeholder: "Enter Date of Complain",
    name: "dateOfComplaint",
    inForm: true,
    wrapperClass: "col-6",
    inputType: "date",
  },
  // {
  //   category: "complain-info",
  //   label: "Complaint Type",
  //   placeholder: "Select Complaint Nature",
  //   name: "type",
  //   inputType: "async-dropdown",
  //   id: "dropdown",
  //   options: [
  //     {
  //       value: "Complaint",
  //       label: "Complaint",
  //     },
  //   ],
  //   onChangeMethod: "asyncdropdown",
  //   inForm: true,
  //   isEnum: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: false,
  // },
  {
    category: "complain-info",
    label: "Description",
    placeholder: "Complaint Description",
    name: "descriptionOfComplaint",
    inForm: true,
    rows: 3,
    wrapperClass: "col-md-6",
    inputType: "textarea",
  },
  {
    category: "complainant",
    children: "Address",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-12 mt-4",
  },
  // {
  //   "category": "complainant",
  //   "label": "Province",
  //   "placeholder": "Enter Province",
  //   "name": "province",
  //   "id": "province",
  //   "inputType": "async-dropdown",
  //   "url": "/Province/GetAll",
  //   "searchAttrib": "name",
  //   "labelAttribute": "name",
  //   "valueAttribute": "id",
  //   "onChangeMethod": "asyncdropdown",
  //   "inForm": true,
  //   "wrapperClass": "col-md-4",
  //   "isMulti": false
  // },
  {
    category: "complainant",
    label: "District",
    placeholder: "Enter District",
    name: "district",
    id: "district",
    inputType: "async-dropdown",
    url: "/District/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    // "condition": {
    //   "fieldName": "province",
    //   "paramField": "ProvinceId",
    //   "action": "useValue"
    // }
  },
  {
    category: "complainant",
    label: "Tehsil",
    placeholder: "Enter Tehsil",
    name: "tehsil",
    id: "tehsil",
    inputType: "async-dropdown",
    url: "/Tehsil/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "district",
      paramField: "DistrictId",
      action: "useValue",
    },
  },
  {
    category: "complainant",
    label: "UC",
    placeholder: "Enter UC",
    name: "unionCouncil",
    id: "unionCouncil",
    inputType: "async-dropdown",
    url: "/UnionCouncil/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "tehsil",
      paramField: "TehsilId",
      action: "useValue",
    },
  },
  {
    category: "complainant",
    label: "Address",
    placeholder: "Enter Address",
    name: "address",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-4",
  },
];
export default complaintFields;
