import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";

const SectorWiseProgress = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 425px)");

  const { BeneficiaryCountByThematicArea, ActivityCountByThematicArea } =
    useSelector((state) => state?.records?.current);

  const [thematicAreaActivity, setThematicAreaActivity] = useState([]);
  const [thematicAreaActivityLabel, setThematicAreaActivityLabel] = useState(
    []
  );
  const [thematicAreaBeneficiary, setThematicAreaBeneficiary] = useState([]);
  const [thematicAreaBeneficiaryLabel, setThematicAreaBeneficiaryLabel] =
    useState([]);

  const activitiesData = {
    name: "Activities",
    data: thematicAreaActivity,
  };
  const beneficiariesData = {
    name: "Beneficiaries",
    data: thematicAreaBeneficiary,
  };

  const [series, setSeries] = useState([activitiesData]);
  const [activeButton, setActiveButton] = useState("Activities");

  // useEffect(() => {
  //   setTimeout(() => {
  //     getData();
  //   }, 200);
  // }, []);

  useEffect(() => {
    if (ActivityCountByThematicArea && BeneficiaryCountByThematicArea) {
      setThematicAreaActivity(
        ActivityCountByThematicArea?.data?.items.map(
          ({ totalActivityCount }) => totalActivityCount
        ) || []
      );
      setThematicAreaActivityLabel(
        ActivityCountByThematicArea?.data?.items.map(
          ({ thematicAreaName }) => thematicAreaName
        ) || []
      );
      setThematicAreaBeneficiary(
        BeneficiaryCountByThematicArea?.data?.items.map(
          ({ totalBeneficiaryCount }) => totalBeneficiaryCount
        ) || []
      );
      setThematicAreaBeneficiaryLabel(
        BeneficiaryCountByThematicArea?.data?.items.map(
          ({ thematicAreaName }) => thematicAreaName
        ) || []
      );
    }
  }, [ActivityCountByThematicArea, BeneficiaryCountByThematicArea]);

  useEffect(() => {
    setSeries([activitiesData]);
    setActiveButton("Activities");
  }, [thematicAreaActivity]);

  const getData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByThematicArea`,
        appendCurrentReducerKey: "BeneficiaryCountByThematicArea",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetActivityCountByThematicArea`,
        appendCurrentReducerKey: "ActivityCountByThematicArea",
      },
    });
  };

  const handleActivitiesClick = () => {
    setSeries([activitiesData]);
    setActiveButton("Activities");
  };

  const handleBeneficiariesClick = () => {
    setSeries([beneficiariesData]);
    setActiveButton("Beneficiaries");
  };

  return (
    <div className=" fundsDonarDistribution">
      <div className="d-flex w-100 justify-content-end align-items-center">
        {/* <Label>Sector Wise Progress</Label> */}

        <span className="mx-1 sector-buttons" onClick={handleActivitiesClick}>
          {activeButton === "Activities" ? (
            <span className="active">Activities</span>
          ) : (
            "Activities"
          )}
        </span>

        <span
          className="mx-1 sector-buttons"
          onClick={handleBeneficiariesClick}
        >
          {activeButton === "Beneficiaries" ? (
            <span className="active">Beneficiaries</span>
          ) : (
            "Beneficiaries"
          )}
        </span>
      </div>
      <div className="d-flex justify-content-center ">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 320}
          height={isMobileView ? 240 : 320}
          series={
            activeButton === "Activities"
              ? thematicAreaActivity
              : thematicAreaBeneficiary
          }
          labels={
            activeButton === "Activities"
              ? thematicAreaActivityLabel
              : thematicAreaBeneficiaryLabel
          }
          position={"bottom"}
          colors={["#554B86", "#0E7BB6"]}
          legendOptions={{
            show: true,
          }}
        />
      </div>
    </div>
  );
};

export default SectorWiseProgress;
