import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "./Sidebar.scss";
import Logo from "../../assets/logo.png";
import MobileLogo from "../../assets/mobileLogo.png";
import { NavLink } from "react-router-dom";
import { canView } from "../../utils/util";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../../Constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import ToolTip from "../tooltip/Tooltip";

const Sidebar = () => {
  const roles = useSelector((state) => state.setting?.user?.userDetail?.roles);
  const userRoles = roles && roles.map(({ name }) => name);
  const isMobileView = useMediaQuery("(max-width: 768px)");


  const sidebarData = [
    {
      id: 1,
      name: "dashboard",
      icon: <Icon.Speedometer2 />,
      path: (
        <NavLink to="/dashboard">
          <span>
            <Icon.Speedometer2 />
          </span>
          <span className="title"><ToolTip label="Dashboard">Dashboard</ToolTip></span>
        </NavLink>
      ),
    },
    {
      id: 2,
      name: "project",
      path: (
        <NavLink to="/projects">
          <span>
            <Icon.ReceiptCutoff />
          </span>
          <span className="title">Projects</span>
        </NavLink>
      ),
    },
    {
      id: 3,
      name: "meal",
      path: (
        <NavLink to="/meal-progress-report">
          <span>
            <Icon.JournalPlus />
          </span>
          <span className="title">MEAL Unit Progress</span>
        </NavLink>
      ),
    },
    {
      id: 4,
      name: "monitoring",
      path: (
        <NavLink to="/monitoring-visit-report">
          <span>
            <Icon.JournalPlus />
          </span>
          <span className="title">Monitoring Visit Report</span>
        </NavLink>
      ),
    },
    {
      id: 5,
      name: "beneficiary",
      title: "Beneficiaries",
      path: (
        <NavLink to="/beneficiaries">
          <span>
            <Icon.PersonWorkspace />
          </span>
          <span className="title">Target Beneficiaries</span>
        </NavLink>
      ),
    },
    {
      id: 6,
      title: "Complaints",
      name: "complaint",
      path: (
        <NavLink to="/complaints">
          <span>
            <Icon.PatchQuestion />
          </span>
          <span className="title">All Complaints</span>
        </NavLink>
      ),
      // children: [
      //   {
      //     id: 7,
      //     title: "Dashboard",
      //     path: (
      //       <NavLink to="/complaints/dashboard">
      //         <span>
      //           <Icon.Speedometer2 />
      //         </span>
      //         <span className="title">Complaint Dashboard</span>
      //       </NavLink>
      //     ),
      //   },
      //   // {
      //   //   id: 8,
      //   //   title: "Add Complaint",
      //   //   path: (
      //   //     <NavLink to="/complaints/add">
      //   //       <span>
      //   //         <Icon.Plus />
      //   //       </span>
      //   //       <span className="title">Add Complaint</span>
      //   //     </NavLink>
      //   //   ),
      //   // },
      // ],
    },
    {
      id: 9,
      name: "user",
      title: "User Management",
      path: (
        <NavLink to="/UserManagement">
          <span>
            <Icon.People />
          </span>
          <span className="title">User Management</span>
        </NavLink>
      ),
    },
    {
      id: 10,
      name: "definition",
      title: "Definitions",
      path: (
        <NavLink to="/definitions/country">
          <span>
            <Icon.List />
          </span>
          <span className="title">Definitions</span>
        </NavLink>
      ),
    },
  ];
  const [selectedTab, setSelectedTab] = useState(1);
  const [sidebarLinks, setSidebarLinks] = useState([]);

  useEffect(() => {
    getSidebarLinks();
  }, [roles]);

  const getSidebarLinks = () => {
    if (
      canView([USER_ROLE.MANAGEMENT], userRoles) &&
      canView([USER_ROLE.MANAGER], userRoles) &&
      canView([USER_ROLE.OFFICER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "dashboard" || name == "project" || name == "complaint"
      );
      setSidebarLinks(data);
    }

    else if
      (
      canView([USER_ROLE.BASE_PROJECT_MANAGER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "project"  || name == "complaint"
      );
      setSidebarLinks(data);
    }


    else if
      (
      canView([USER_ROLE.BASE_COUNTRY_MANAGER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "project"  || name == "complaint"
      );
      setSidebarLinks(data);
    }


    else if (
      canView([USER_ROLE.MANAGEMENT], userRoles) &&
      canView([USER_ROLE.MANAGER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "dashboard" || name == "project" || name == "complaint"
      );
      setSidebarLinks(data);
    } else if (
      canView([USER_ROLE.MANAGEMENT], userRoles) &&
      canView([USER_ROLE.OFFICER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "dashboard" || name == "project"
      );
      setSidebarLinks(data);
    } else if (
      canView([USER_ROLE.MANAGER], userRoles) &&
      canView([USER_ROLE.OFFICER], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "complaint" || name == "project"
      );
      setSidebarLinks(data);
    } else if (canView([USER_ROLE.MANAGEMENT], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) => name == "dashboard" || name == "project"
      );
      setSidebarLinks(data);
    } else if (canView([USER_ROLE.OFFICER], userRoles)) {
      const data = sidebarData.filter(({ name }) => name == "project");
      setSidebarLinks(data);

    } else if (canView([USER_ROLE.MANAGER], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) => name == "project" || name == "complaint"
      );
      setSidebarLinks(data);
    }

    else {
      setSidebarLinks(sidebarData);
    }
  };

  return (
    <div className="wrap">
      <a href="/" className="logo">
        <img
          width={"100rem"}
          style={{ background: "#0075B6", borderRadius: "10px" }}
          src={Logo}
          alt="logo"
        />
      </a>
      <a href="/" className="mobileLogo">
        <img src={MobileLogo} alt="logo" />
      </a>
      <hr className="line1" />
      <ul className="items">
        {sidebarLinks.length > 0 &&
          sidebarLinks.map((i) => {
            return (
              <li
                className={`nav-link ${i.id === selectedTab ? "active" : ""} ${i.children && "has-children"
                  }`}
                key={i.id}
                onClick={() => setSelectedTab(i.id)}
              >
                {i.path}
                {i.children && (
                  <ul className="items child-items">
                    {i.children.map((j, i) => {
                      return (
                        <li
                          className={`nav-link pl-2 ${j.id === selectedTab ? "active" : ""
                            }`}
                          key={`child-${j.id}-${i}`}
                          // key={`${j.id}`}
                          onClick={() => setSelectedTab(j.id)}
                        >
                          {j.path}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
      </ul>
      {/* <hr className="line1" /> */}
<div>
{!isMobileView && <small className="text-dark p-2 pt-4 d-block text-center copyright">Powered by Centangle Interactive<br />Version: 1.30.5</small>}

</div>
    </div>
  );
};

export default Sidebar;
