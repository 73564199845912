import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SearchList from "../../components/search-list/SearchList";
import Table from "../../components/table/Table";
import ProjectColumns from "../../assets/columns/ProjectColumns";
import Loader from "../../components/loader/loader";
import { createRecord, getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION, USER_ROLE } from "../../Constants";
import DeleteRow from "../DeleteRow";
import ListHeader from "../../components/list-header/ListHeader";
import "./Projects.scss";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import useUserRole from "../../hooks/useUserRole";
import { canView } from "../../utils/util";

const Projects = () => {
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const [hideSearchBar, setHideSearchBar] = useState(true);

  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  const { items = [] } = data;
  const sdg = current?.sdg?.data?.items;

  useEffect(() => {
    setMeta({ title: "All Projects" });
    getProject();
    // setTimeout(() => {
    //   getSdgs();
    // }, 1000);
  }, [created]);

  const getProject = (SDGNo = "") => {
    let params = { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC };
    params = SDGNo ? { ...params, SDGNo } : params;
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/Project/GetAll",
        params: params,
      },
    });
  };

  const getSdgs = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/SDG/GetAll`,
        appendCurrentReducerKey: "sdg",
      },
    });
  };

  const onFilterClick = () => {
    setHideSearchBar(!hideSearchBar);
    if (hideSearchBar) getSdgs();
  };

  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: { url: `/Project/Approve?id=${id}` },
    });
  };

  const handleApproveForStart = (id) => {
    dispatch({
      type: createRecord.type,
      payload: { url: `/Project/ApproveForStart?id=${id}` },
    });
  };

  const columns = [
    ...ProjectColumns,
    {
      name: "Action",
      width: "180px",
      selector: (row) => (
        <>
          {row.canView && (
            <ToolTip label="Details">
              <Link to={`/projects/${row?.id}`}>
                <Icon.Eye size={20} className="text-primary mr-2" />
              </Link>
            </ToolTip>
          )}
          {row.canEdit && (
            <ToolTip label="Edit">
              <Link to={`/projects/update/${row.id}`}>
                <Icon.PencilSquare size={18} className="action-icons" />
              </Link>
            </ToolTip>
          )}
          {row.canDelete && <DeleteRow row={row} url="/Project" />}

          {canView(
            [
              USER_ROLE.ADMIN,
              USER_ROLE.SUPER_ADMIN,
              USER_ROLE.BASE_PROJECT_MANAGER,
              USER_ROLE.PROJECT_MANAGER,
              USER_ROLE.COUNTRY_MANAGER,
              USER_ROLE.BASE_COUNTRY_MANAGER,
            ],
            userRoles
          ) &&
            !row?.isApproved && (
              <>
                <ToolTip
                  label={`${
                    row?.status !== "Ongoing"
                      ? "Approve"
                      : "Can't Approve Before Completion!"
                  }`}
                >
                  <Icon.Check
                    size={20}
                    className={`text-success h5 mt-1 cursor-pointer action-icons ${
                      row?.status === "Ongoing" ? "text-muted" : ""
                    }`}
                    onClick={() => {
                      row?.status !== "Ongoing" && handleApprove(row?.id);
                    }}
                  />
                </ToolTip>
              </>
            )}

          {canView(
            [
              USER_ROLE.ADMIN,
              USER_ROLE.SUPER_ADMIN,
              USER_ROLE.BASE_PROJECT_MANAGER,
              USER_ROLE.PROJECT_MANAGER,
              USER_ROLE.COUNTRY_MANAGER,
              USER_ROLE.BASE_COUNTRY_MANAGER,
            ],
            userRoles
          ) &&
            !row?.isApprovedForStart && (
              <>
                <ToolTip label="Approve For Start">
                  <Icon.PlayBtn
                    size={22}
                    className="text-success h5 mt-1 cursor-pointer action-icons "
                    onClick={() => handleApproveForStart(row?.id)}
                  />
                </ToolTip>
              </>
            )}
        </>
      ),
    },
  ];

  let icons = [];

  canView(
    [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.BASE_PROJECT_MANAGER,
      USER_ROLE.BASE_COUNTRY_MANAGER,
    ],
    userRoles
  ) &&
    icons.push({
      id: 1,
      name: "PlusCircle",
      url: "/projects/add",
      itemClassName: "btn btn-success d-flex align-items-center",
      iconColor: "#fff",
      label: "Add New Project",
      text: "Add Project",
    });

  return (
    <>
      <div className="sub d-md-flex justify-content-between align-items-center mb-3">
        <div className="right d-md-flex w-50 py-2">
          <SearchList
            url="/Project/GetAll"
            params={{
              DisablePagination: true,
              OrderDir: ORDER_DIRECTION.DESC,
            }}
            searchFieldName="Title"
            placeholder="Search Project"
          />
          <div
            className={`${
              hideSearchBar ? "d-none" : "d-flex align-items-center ml-3"
            } `}
          >
            <label htmlFor="" className="mr-2 mb-0">
              Select SDG:
            </label>
            <select
              className="w-md-50 w-75 form-control overflow-auto"
              onChange={({ target }) => getProject(target.value)}
            >
              <option disabled selected>
                Select
              </option>
              <option value="">Show All</option>
              {sdg &&
                sdg.map(({ name, sdgNo }) => (
                  <option value={sdgNo} key={sdgNo}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="right text-right mt-2">
          <ListHeader options={icons}>
            <ToolTip label="Filter Projects">
              <button
                className="btn btn-primary ml-auto d-flex mb-2 align-items-center mr-2"
                onClick={() => onFilterClick()}
              >
                <Icon.Funnel size={20} />
                <span className="ml-2">Filter</span>
              </button>
            </ToolTip>
          </ListHeader>
        </div>
      </div>
      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} data={items} canExport={true} />
      </div>
    </>
  );
};

export default Projects;
